import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Loading, MessageBox, Notification, Message } from "element-ui";
import "./assets/css/reset.scss";
import "./assets/css/global.scss";
import axios from "@/utils/axios.init";
import api from "@/utils/api";

Vue.config.productionTip = false;

Vue.prototype.$ELEMENT = { size: 'small', zIndex: 50 };
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;


Vue.use(Loading.directive);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
