import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    menusFoldStatus: false, //菜单折叠状态
    userInfo: {}, //用户信息
    userPermissions: "", //用户权限
  },
  getters: {},
  mutations: {
    //切换菜单折叠状态
    SET_MENUS_FOLD_STATUS(state) {
      state.menusFoldStatus = !state.menusFoldStatus;
    },
    //设置用户信息
    SET_USER_INFO(state, val) {
      state.userInfo = val;
    },
    //设置用户权限
    SET_USER_PERMISSIONS(state, val) {
      state.userPermissions = val;
    },
  },
  actions: {},
  modules: {},
});
