import Vue from "vue";
import VueRouter from "vue-router";
// 解决两次访问相同路由地址报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

// 常量路由 所有角色可见
export const constantRoutes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
  },
  {
    path: "*",
    name: "dashboard",
    component: () => import("@/views/dashboard"),
  },
];

// 异步路由 根据角色过滤可见
export const asyncRoutes = [
  //首页
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home"),
  },
  //个人中心
  {
    path: "/center",
    name: "center",
    component: () => import("@/views/center"),
  },
  // 健康师入驻审核列表
  {
    path: "/health/join",
    name: "healthJoin",
    component: () => import("@/views/health/join/index"),
  },
  // 健康师入驻审核修改
  {
    path: "/health/join/update",
    name: "joinUpdate",
    component: () => import("@/views/health/join/update"),
  },
  // 健康师资料审核列表
  {
    path: "/health/aptitude",
    name: "healthAptitude",
    component: () => import("@/views/health/aptitude/index"),
  },
  // 健康师资料审核修改
  {
    path: "/health/aptitude/update",
    name: "healthAptitudeUpdate",
    component: () => import("@/views/health/aptitude/update"),
  },
  // 健康师资料审核详情
  {
    path: "/health/aptitude/detail",
    name: "aptitudeDetail",
    component: () => import("@/views/health/aptitude/detail"),
  },
  // 健康师信息管理列表
  {
    path: "/health/intel",
    name: "healthIntel",
    component: () => import("@/views/health/intel/index"),
  },
  // 健康师信息管理新增入驻
  {
    path: "/health/intel/addJoin",
    name: "intelAddJoin",
    component: () => import("@/views/health/intel/addJoin"),
  },
  // 健康师信息管理入驻详情
  {
    path: "/health/intel/detailJoin",
    name: "healthIntelDetailJoin",
    component: () => import("@/views/health/intel/detailJoin"),
  },
  // 健康师信息管理修改入驻
  {
    path: "/health/intel/updateJoin",
    name: "healthIntelUpdateJoin",
    component: () => import("@/views/health/intel/updateJoin"),
  },
  // 健康师信息管理新增资料
  {
    path: "/health/intel/addIntel",
    name: "healthIntelAddIntel",
    component: () => import("@/views/health/intel/addIntel"),
  },
  // 健康师信息管理资料详情
  {
    path: "/health/intel/detailIntel",
    name: "healthIntelDetailIntel",
    component: () => import("@/views/health/intel/detailIntel"),
  },
  // 健康师信息管理资料详情
  {
    path: "/health/intel/updateIntel",
    name: "healthIntelUpdateIntel",
    component: () => import("@/views/health/intel/updateIntel"),
  },
  // 门店管理入驻审核列表
  {
    path: "/store/join",
    name: "storeJoin",
    component: () => import("@/views/store/join/index"),
  },
  // 门店管理入驻审核修改
  {
    path: "/store/join/update",
    name: "storeJoinUpdate",
    component: () => import("@/views/store/join/update"),
  },
  // 门店管理门店审核列表
  {
    path: "/store/assess",
    name: "storeAssess",
    component: () => import("@/views/store/assess/index"),
  },
  // 门店管理入驻审核详情
  {
    path: "/store/assess/detail",
    name: "storeAssessDetail",
    component: () => import("@/views/store/assess/detail"),
  },
  // 门店管理入驻审核修改
  {
    path: "/store/assess/update",
    name: "storeAssessUpdate",
    component: () => import("@/views/store/assess/update"),
  },
  // 门店管理资料审核列表
  {
    path: "/store/aptitude",
    name: "storeAptitude",
    component: () => import("@/views/store/aptitude/index"),
  },
  // 门店管理资料审核详情
  {
    path: "/store/aptitude/detail",
    name: "storeAptitudeDetail",
    component: () => import("@/views/store/aptitude/detail"),
  },
  // 门店管理资料审核修改
  {
    path: "/store/aptitude/update",
    name: "storeAptitudeUpdate",
    component: () => import("@/views/store/aptitude/update"),
  },
  // 门店管理门店老板列表
  {
    path: "/store/boss",
    name: "storeBoss",
    component: () => import("@/views/store/boss/index"),
  },
  // 门店管理门店老板新增
  {
    path: "/store/boss/add",
    name: "storeBossAdd",
    component: () => import("@/views/store/boss/add"),
  },
  // 门店管理门店老板新增
  {
    path: "/store/boss/update",
    name: "storeBossUpdate",
    component: () => import("@/views/store/boss/update"),
  },
  // 门店管理门店老板详情
  {
    path: "/store/boss/detail",
    name: "storeBossDetail",
    component: () => import("@/views/store/boss/detail"),
  },
  // 门店管理门店管理列表
  {
    path: "/store/manage",
    name: "storeManage",
    component: () => import("@/views/store/manage/index"),
  },
  // 门店管理门店新增入驻
  {
    path: "/store/manage/addJoin",
    name: "storeManageaAddJoin",
    component: () => import("@/views/store/manage/addJoin"),
  },
  // 门店管理门店修改入驻
  {
    path: "/store/manage/updateJoin",
    name: "storeManageaUpdateJoin",
    component: () => import("@/views/store/manage/updateJoin"),
  },
  // 门店管理门店新增资料
  {
    path: "/store/manage/addAptitude",
    name: "storeManageaAddAptitude",
    component: () => import("@/views/store/manage/addAptitude"),
  },
  // 门店管理门店修改资料
  {
    path: "/store/manage/updateAptitude",
    name: "storeManageaUpdateAptitude",
    component: () => import("@/views/store/manage/updateAptitude"),
  },
  // 门店管理门店详情
  {
    path: "/store/manage/detail",
    name: "storeManageaDetail",
    component: () => import("@/views/store/manage/detail"),
  },
  // 门店管理门店员工
  {
    path: "/store/manage/staff",
    name: "storeManageaStaff",
    component: () => import("@/views/store/manage/staff"),
  },
  // 项目管理项目审核列表
  {
    path: "/project/audit",
    name: "projectAudit",
    component: () => import("@/views/project/audit"),
  },
  // 项目管理项目套餐审核详情
  {
    path: "/project/audit/detail",
    name: "projectAuditDetail",
    component: () => import("@/views/project/audit/detail"),
  },
  // 项目管理项目套餐列表
  {
    path: "/project/list",
    name: "projectList",
    component: () => import("@/views/project/list"),
  },
  // 项目管理项目套餐详情
  {
    path: "/project/list/detail",
    name: "projectListDetail",
    component: () => import("@/views/project/list/detail"),
  },
  // 优惠券
  {
    path: "/coupon/list",
    name: "couponList",
    component: () => import("@/views/coupon/list"),
  },
  // 平台管理横幅管理
  {
    path: "/platform/banner",
    name: "platformBanner",
    component: () => import("@/views/platform/banner"),
  },
  // 平台管理搜索词管理
  {
    path: "/platform/search",
    name: "platformSearch",
    component: () => import("@/views/platform/search"),
  },
  // 资金管理退款申请
  {
    path: "/fund/refund",
    name: "fundRefund",
    component: () => import("@/views/fund/refund"),
  },
  // 资金管理钱包明细
  {
    path: "/fund/refund/wallet",
    name: "fundRefundWallet",
    component: () => import("@/views/fund/refund/wallet"),
  },
  // 资金管理提现申请
  {
    path: "/fund/withdraw",
    name: "fundWithdraw",
    component: () => import("@/views/fund/withdraw"),
  },
  // 资金管理账户明细
  {
    path: "/fund/withdraw/account",
    name: "fundWithdrawAccount",
    component: () => import("@/views/fund/withdraw/account"),
  },
  // 订单管理服务明细
  {
    path: "/order/serve",
    name: "orderServe",
    component: () => import("@/views/order/serve"),
  },
  // 订单管理服务明细服务详情
  {
    path: "/order/serve/detail",
    name: "orderServeDetail",
    component: () => import("@/views/order/serve/detail"),
  },
  // 商品管理商品分类
  {
    path: "/goods/type",
    name: "goodsType",
    component: () => import("@/views/goods/type"),
  },
  // 商品管理商品信息
  {
    path: "/goods/info",
    name: "goodsInfo",
    component: () => import("@/views/goods/info"),
  },
  // 商品管理商品信息商品新增
  {
    path: "/goods/info/add",
    name: "goodsInfoAdd",
    component: () => import("@/views/goods/info/add"),
  },
  // 商品管理商品信息商品详情
  {
    path: "/goods/info/detail",
    name: "goodsInfoDetail",
    component: () => import("@/views/goods/info/detail"),
  },
  // 商品管理商品信息商品详情
  {
    path: "/goods/info/update",
    name: "goodsInfoUpdate",
    component: () => import("@/views/goods/info/update"),
  },
  // 商品管理商品销售
  {
    path: "/goods/sale",
    name: "goodsSale",
    component: () => import("@/views/goods/sale"),
  },
  // 商品管理商品销售销售新增
  {
    path: "/goods/sale/add",
    name: "goodsSaleAdd",
    component: () => import("@/views/goods/sale/add"),
  },
  // 商品管理商品销售销售详情
  {
    path: "/goods/sale/detail",
    name: "goodsSaleDetail",
    component: () => import("@/views/goods/sale/detail"),
  },
  // 商品管理商品销售销售修改
  {
    path: "/goods/sale/update",
    name: "goodsSaleUpdate",
    component: () => import("@/views/goods/sale/update"),
  },
  // 商品管理培训章节
  {
    path: "/goods/chapter",
    name: "goodsChapter",
    component: () => import("@/views/goods/chapter"),
  },
  // 商品管理培训课程
  {
    path: "/goods/course",
    name: "goodsCourse",
    component: () => import("@/views/goods/course"),
  },
  // 商品管理商品订单
  {
    path: "/goods/order",
    name: "goodsOrder",
    component: () => import("@/views/goods/order"),
  },
  // 商品管理商品订单详情
  {
    path: "/goods/order/detail",
    name: "goodsOrderDetail",
    component: () => import("@/views/goods/order/detail"),
  },
  // // 商品管理商品订单
  // {
  //   path: "/goods/order",
  //   name: "goodsOrder",
  //   component: () => import("@/views/goods/order"),
  // },
  // 官网管理
  {
    path: "/website/apply",
    name: "websiteApply",
    component: () => import("@/views/website/apply"),
  },
  // 黑名单
  {
    path: "/website/apply/blacklist",
    name: "websiteApplyBlacklist",
    component: () => import("@/views/website/apply/blacklist"),
  },
  // 新闻管理
  {
    path: "/website/news",
    name: "websiteNews",
    component: () => import("@/views/website/news"),
  },
  // 新闻新增
  {
    path: "/website/news/add",
    name: "websiteNewsAdd",
    component: () => import("@/views/website/news/add"),
  },
  // 新闻修改
  {
    path: "/website/news/update",
    name: "websiteNewsUpdate",
    component: () => import("@/views/website/news/update"),
  },
  // 新闻类型
  {
    path: "/website/news/type",
    name: "websiteNewsType",
    component: () => import("@/views/website/news/type"),
  },

  //菜单管理
  {
    path: "/system/menu",
    name: "systemMenu",
    component: () => import("@/views/system/menu/index"),
  },
  //菜单新增
  {
    path: "/system/menu/add",
    name: "systemMenuAdd",
    component: () => import("@/views/system/menu/add"),
  },
  //菜单修改
  {
    path: "/system/menu/update",
    name: "systemMenuUpdate",
    component: () => import("@/views/system/menu/update"),
  },
  //菜单详情
  {
    path: "/system/menu/detail",
    name: "systemMenuDetail",
    component: () => import("@/views/system/menu/detail"),
  },
  //角色管理
  {
    path: "/system/role",
    name: "systemRole",
    component: () => import("@/views/system/role/index"),
  },
  //角色新增
  {
    path: "/system/role/add",
    name: "systemRoleAdd",
    component: () => import("@/views/system/role/add"),
  },
  //角色修改
  {
    path: "/system/role/update",
    name: "systemRoleUpdate",
    component: () => import("@/views/system/role/update"),
  },
  //角色详情
  {
    path: "/system/role/detail",
    name: "systemRoleDetail",
    component: () => import("@/views/system/role/detail"),
  },
  //账号管理
  {
    path: "/system/account",
    name: "systemAccount",
    component: () => import("@/views/system/account/index"),
  },
  //账号新增
  {
    path: "/system/account/add",
    name: "systemAccountAdd",
    component: () => import("@/views/system/account/add"),
  },
  //账号修改
  {
    path: "/system/account/update",
    name: "systemAccountUpdate",
    component: () => import("@/views/system/account/update"),
  },
  //账号详情
  {
    path: "/system/account/detail",
    name: "systemAccountDetail",
    component: () => import("@/views/system/account/detail"),
  },
  {
    //列表页面 temp
    path: "/temp/list",
    name: "tempList",
    component: () => import("@/views/temp/index"),
  },
  {
    //新增页面 temp
    path: "/temp/add",
    name: "tempAdd",
    component: () => import("@/views/temp/add"),
  },
  {
    //修改页面 temp
    path: "/temp/update",
    name: "tempUpdate",
    component: () => import("@/views/temp/update"),
  },
  {
    //详情页面 temp
    path: "/temp/detail",
    name: "tempDetail",
    component: () => import("@/views/temp/detail"),
  },
];

const createRouter = () =>
  new VueRouter({
    mode: "history",
    base: "/",
    routes: constantRoutes,
  });

const router = createRouter();

// 提示路由
export function hintRouter() {
  router.addRoute("dashboard", {
    path: "/401",
    name: "401",
    component: () => import("@/views/401"),
  });
  router.addRoute("dashboard", {
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
  });
}

// 重置路由
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
