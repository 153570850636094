const managerUrl = "/api"; // 认证层接口地址 正式环境

const api = {
  public: {
    getArea: `${managerUrl}/v6/system/area/children`, // 获取地区列表
  },
  system: {
    loginCode: `${managerUrl}/v6/mgr/user/login/yzm`, // 验证码登录
    getCode: `${managerUrl}/v6/mgr/user/login/getyzm`, // 获取验证码
    loginPwd: `${managerUrl}/v6/mgr/user/login/pwd`, // 密码登录
    loginOut: `${managerUrl}/manager/user/login/out`, // 退出登录
    getRoles: `${managerUrl}/v6/mgr/user/menus`, //获取权限列表
    userInfo: `${managerUrl}/v6/mgr/user/info`, //用户信息
    updateUserAvatar: `${managerUrl}/v6/mgr/user/upava`, //修改用户头像
    updateUserPwd: `${managerUrl}/v6/mgr/user/uppwd`, // 修改用户密码
    areaGcj02: `${managerUrl}/v6/system/area/gcj02`, // 地区定位接口
    oss: {
      pmmUserAvatarImg: `${managerUrl}/v6/system/oss/policy?business=pmmUserAvatarImg`, // 用户头像
      pmmUserIdentityImg: `${managerUrl}/v6/system/oss/policy?business=pmmUserIdentityImg`, // 身份证
      pmmUserCertImg: `${managerUrl}/v6/system/oss/policy?business=pmmUserCertImg`, // 资质证书
      pmmUserVideoImg: `${managerUrl}/v6/system/oss/policy?business=pmmUserVideoImg`, // 视频封面
      pmmUserVideoVideo: `${managerUrl}/v6/system/oss/policy?business=pmmUserVideoVideo`, // 用户视频
      pmmUserWorkImg: `${managerUrl}/v6/system/oss/policy?business=pmmUserWorkImg`, // 工作照
      pmmShopLicenseImg: `${managerUrl}/v6/system/oss/policy?business=pmmShopLicenseImg`, // 工作照
      pmmStoreAvatarImg: `${managerUrl}/v6/system/oss/policy?business=pmmStoreAvatarImg`, // 门店头像
      pmmStorePicImg: `${managerUrl}/v6/system/oss/policy?business=pmmStorePicImg`, // 门店照片
      pmmStoreVideoVideo: `${managerUrl}/v6/system/oss/policy?business=pmmStoreVideoVideo`, // 门店视频
      pmmSystemBannerImg: `${managerUrl}/v6/system/oss/policy?business=pmmSystemBannerImg`, // 系统横幅
      pmmMallGoodsImg: `${managerUrl}/v6/system/oss/policy?business=pmmMallGoodsImg`, // 商城商品图片
      pmmMallGoodsVideo: `${managerUrl}/v6/system/oss/policy?business=pmmMallGoodsVideo`, // 商城商品视频
    },
  },
  // 健康师管理
  health: {
    joinList: `${managerUrl}/v6/tech/join/unlist`, // 健康师入驻待审核列表
    joinExamine: `${managerUrl}/v6/tech/join/examine`, // 健康师入驻审核
    joinInfo: `${managerUrl}/v6/tech/join/info`, // 健康师入驻审核信息
    healthType: `${managerUrl}/v6/tech/types`, // 健康师类型
    joinUpdate: `${managerUrl}/v6/tech/join/update`, // 健康师入驻信息修改
    intelList: `${managerUrl}/v6/tech/list`, // 健康师信息管理列表
    intelEnable: `${managerUrl}/v6/user/info/techmgr`, // 健康师信息管理启用禁用
    intelAddJoin: `${managerUrl}/v6/tech/join/add`, // 健康师信息管理新增入驻
    aptitudeList: `${managerUrl}/v6/tech/intro/unlist`, // 健康师资料待审核列表
    aptitudeExamine: `${managerUrl}/v6/tech/intro/examine`, // 健康师资料审核
    aptitudeAdd: `${managerUrl}/v6/tech/intro/add`, // 健康师资料新增
    aptitudeDetail: `${managerUrl}/v6/tech/intro/undetail`, // 健康师未审核资料详情
    aptitudeInfo: `${managerUrl}/v6/tech/intro/info`, // 健康师审核通过资料详情
    introUpdate: `${managerUrl}/v6/tech/intro/update`, // 健康师资料修改
    introUnupdate: `${managerUrl}/v6/tech/intro/unupdate`, // 健康师资料待审核修改
  },
  // 门店管理
  store: {
    bossUnlist: `${managerUrl}/v6/store/boss/unlist`, // 老板入驻待审核列表
    bossExamine: `${managerUrl}/v6/store/boss/examine`, // 老板入驻待审核
    bossInfo: `${managerUrl}/v6/store/boss/info`, // 老板入驻信息
    bossInfoUpdate: `${managerUrl}/v6/store/boss/update`, // 老板入驻信息修改
    bossList: `${managerUrl}/v6/store/boss/list`, // 老板列表
    bossAdd: `${managerUrl}/v6/store/boss/add`, // 老板入驻新增
    bossMgr: `${managerUrl}/v6/store/boss/mgr`, // 老板启用禁用
    baseUnlist: `${managerUrl}/v6/store/base/unlist`, // 门店入驻待审核列表
    baseInfo: `${managerUrl}/v6/store/base/query`, // 门店查询
    baseExamine: `${managerUrl}/v6/store/base/examine`, // 门店入驻审核
    baseUpdate: `${managerUrl}/v6/store/base/update`, // 门店修改
    infoUnlist: `${managerUrl}/v6/store/info/unlist`, // 门店资料待审核
    infoUnintro: `${managerUrl}/v6/store/info/unintro`, // 门店资料待审核详情
    infoExamine: `${managerUrl}/v6/store/info/examine`, // 门店信息审核
    infoUnupdate: `${managerUrl}/v6/store/info/unupdate`, // 门店信息审核
    storeList: `${managerUrl}/v6/store/list`, // 门店列表
    storeExamine: `${managerUrl}/v6/store/info/mgr`, // 门店启用禁用
    bossItem: `${managerUrl}/v6/store/boss/items`, // 门店老板下拉信息
    baseList: `${managerUrl}/v6/store/base/list`, // 门店老板详情门店列表
    baseAdd: `${managerUrl}/v6/store/base/add`, // 门店入驻新增
    infoUpdate: `${managerUrl}/v6/store/info/update`, // 门店信息修改
    infoQuery: `${managerUrl}/v6/store/info/query`, // 门店信息详情
    infoAdd: `${managerUrl}/v6/store/info/add`, // 门店信息详情
    storeType: `${managerUrl}/v6/pro/type/children`, // 门店类型
    staffList: `${managerUrl}/v6/store/staff/list`, // 门店员工
  },
  // 优惠券
  coupon: {
    list: `${managerUrl}/v6/coupon/list`, // 优惠券列表
  },
  // 项目套餐
  project: {
    infoList: `${managerUrl}/v6/pro/info/list`, // 项目套餐列表
    infoUnlist: `${managerUrl}/v6/pro/info/unlist`, // 项目待审核列表
    infoExamine: `${managerUrl}/v6/pro/info/examine`, // 项目审核
    projectType: `${managerUrl}/v6/pro/type/children`, // 项目类别
    infoMgr: `${managerUrl}/v6/pro/info/mgr`, // 项目禁用启用
    infoLast: `${managerUrl}/v6/pro/info/last`, // 项目最新信息
    infoQuery: `${managerUrl}/v6/pro/info/query`, // 项目信息
    infoSetMgr: `${managerUrl}/v6/pro/info/setMgr`, // 项目禁用启用
  },
  // 平台管理
  platform: {
    bannerPlaces: `${managerUrl}/v6/system/banner/places`, // 横幅位置
    bannerList: `${managerUrl}/v6/system/banner/list`, // 横幅列表
    bannerMgr: `${managerUrl}/v6/system/banner/mgr`, // 横幅启用禁用
    bannerAdd: `${managerUrl}/v6/system/banner/add`, // 横幅新增
    bannerUpdate: `${managerUrl}/v6/system/banner/update`, // 横幅修改
    wordsList: `${managerUrl}/v6/search/words/list`, // 热搜词列表
    wordsMgr: `${managerUrl}/v6/search/words/mgr`, // 热搜词启用禁用
    wordsAdd: `${managerUrl}/v6/search/words/add`, // 热搜词新增
    wordsUpdate: `${managerUrl}/v6/search/words/update`, // 热搜词修改
  },
  // 资金管理
  fund: {
    refundList: `${managerUrl}/v6/money/wallet/refund/list`, // 退款申请列表
    refundMgr: `${managerUrl}/v6/money/wallet/refund/mgr`, // 退款通过拒绝
    walletList: `${managerUrl}/v6/money/wallet/list`, // 钱包明细列表
    withdrawList: `${managerUrl}/v6/account/withdraw/list`, // 提现列表
    withdrawMgr: `${managerUrl}/v6/account/withdraw/mgr`, // 提现操作
    infoList: `${managerUrl}/v6/account/info/list`, // 账户明细列表
  },
  //商品管理
  goods: {
    typeBlist: `${managerUrl}/v6/goods/type/blist`, // 业务分类列表
    typeGlist: `${managerUrl}/v6/goods/type/glist`, // 商品分类列表
    typeAdd: `${managerUrl}/v6/goods/type/add`, // 商品分类新增
    typeDetail: `${managerUrl}/v6/goods/type/detail`, // 商品分类详情
    typeUpdate: `${managerUrl}/v6/goods/type/update`, // 商品分类修改
    infoList: `${managerUrl}/v6/goods/info/list`, // 商品信息列表
    infoAdd: `${managerUrl}/v6/goods/info/add`, // 商品信息新增
    infoQuery: `${managerUrl}/v6/goods/info/query`, // 商品信息查询
    infoUpdate: `${managerUrl}/v6/goods/info/update`, // 商品信息修改
    saleList: `${managerUrl}/v6/goods/sale/list`, // 商品销售列表
    saleAdd: `${managerUrl}/v6/goods/sale/add`, // 商品销售新增
    saleDetail: `${managerUrl}/v6/goods/sale/detail`, // 商品销售详情
    saleUpdate: `${managerUrl}/v6/goods/sale/update`, // 商品销售修改
    chaptersList: `${managerUrl}/v6/goods/chapters/list`, // 课程章节列表
    chaptersAdd: `${managerUrl}/v6/goods/chapters/add`, // 课程章节新增
    chaptersDetail: `${managerUrl}/v6/goods/chapters/detail`, // 课程章节详情
    chaptersUpdate: `${managerUrl}/v6/goods/chapters/update`, // 课程章节修改
    classesList: `${managerUrl}/v6/goods/classes/list`, // 培训课程列表
    classesAdd: `${managerUrl}/v6/goods/classes/add`, // 培训课程新增
    classesDetail: `${managerUrl}/v6/goods/classes/detail`, // 培训课程详情
    classesUpdate: `${managerUrl}/v6/goods/classes/update`, // 培训课程修改
    orderMgrlist: `${managerUrl}/v6/goods/order/mgrlist`, // 商品订单搜索
    orderAllinfo: `${managerUrl}/v6/goods/order/allinfo`, // 商品订单资料
    orderSend: `${managerUrl}/v6/goods/order/send`, // 商品订单发货
    orderLogisticsList: `${managerUrl}/v6/goods/order/logisticsList`, // 物流列表
    orderLogistics: `${managerUrl}/v6/goods/order/logistics`, // 商品订单物流更新
    orderReturn: `${managerUrl}/v6/goods/order/return`, // 商品订单退货
    orderArrival: `${managerUrl}/v6/goods/order/arrival`, // 商品订单到货
  },
  // 订单管理
  order: {
    serverList: `${managerUrl}/v6/order/server/list`, // 服务列表
    serverInfo: `${managerUrl}/v6/order/server/info`, // 服务信息
  },
  // 官网
  website: {
    newsAdd: `${managerUrl}/v6/website/news/add`, //新闻新增
    newsMgrlist: `${managerUrl}/v6/website/news/mgrlist`, //新闻列表
    newsMgr: `${managerUrl}/v6/website/news/mgr`, //新闻列表，是否首页和是否展示切换
    newsDetail: `${managerUrl}/v6/website/news/detail`, //新闻详情
    newsUpdate: `${managerUrl}/v6/website/news/update`, //新闻修改
    mgrlist: `${managerUrl}/v6/website/join/mgrlist`,//加入我们列表
    joinMgr: `${managerUrl}/v6/website/join/mgr`,//加入我们操作

  }
};

export default api;
